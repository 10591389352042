

const api_url = 'https://us-central1-danki-ly.cloudfunctions.net/widgets/';
//const domain_name = 'https://danki.ly/';




//const api_url = 'http://localhost:5001/danki-ly/us-central1/widgets/';
const domain_name = 'http://danki.ly/';




export {api_url,domain_name};