import {useState, useEffect} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";



import Login from './Login.js';

import Produto from './Produto.js';


import {auth, db, storage} from './firebase.js';


export function Redirect(){
  let {slug} = useParams();
  useEffect(() => {
    //Redirecionar!
    
    db.collection('linksGeral').doc(slug)
            .onSnapshot(function(doc){
            if(doc.data()){
            window.location.href = doc.data().url_final;
            }else{
              window.location.href = 'https://dankicode.com';
            }
                   
    })
  }, [])
  return (
    <div style={{textAlign:"center"}}><h2>Redirecting...</h2></div>
  )
}

function App() {

  const [login,setLogin] = useState(null);


  return (
    <Router>
      <div>
        <Switch>
        <Route path="/product">
            <Produto login={login} setLogin={setLogin} />
          </Route>
          <Route path="/login">
            <Login login={login} setLogin={setLogin} />
          </Route>
          <Route path="/about">
            <div className="about">
              <h3>About Danki.ly</h3>
              <p>Lorem ipsum silor domor amet.</p>
            </div>
          </Route>
          <Route path="/:slug">
              <Redirect />
          </Route>
          <Route path="/">
            <Login login={login} setLogin={setLogin} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
