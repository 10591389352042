import { useEffect } from "react";
import {auth, provider,db} from './firebase';
import { useHistory } from "react-router-dom";
import firebase from 'firebase';
import {api_url,domain_name} from './Api';
import logoImage from './logo.png';

import {AiOutlineUser} from "react-icons/ai";
import {BiUserCircle} from "react-icons/bi";

export default function Login(props){

    var history = useHistory();
    useEffect(()=>{
        
        //Sistema de login persistente.
        auth.onAuthStateChanged((val)=>{
            if(val){
            
                props.setLogin({
                nome: val.displayName,
                email:val.email,
                imagem: val.photoURL,
                uid: val.uid
                });
                
                history.push('/product');
                
        }
            
  
        })
    },[])
  
    function handleLogin(e){
        e.preventDefault();
        auth.signInWithPopup(provider)
        .then(function(result){
            if(result){
                props.setLogin(result.user.email);
                history.push('/product');
            }
        })
    }

   async function shortLinkTest(e){
        e.preventDefault();

        let shortedTest = window.sessionStorage.getItem('shortedTest');

        if(!shortedTest){

        let linkTest = document.getElementById('link-test').value;
        
        var finalLink = "";

        await fetch(api_url+'generateonelink?link='+linkTest)
                .then(response => response.json())
                .then(function(data){
                    if(data.link != null){
                        window.sessionStorage.setItem('shortedTest', 'true');
                        finalLink = data.link;
                        console.log(data.link);
                    }
                    else if(data.error != null){
                        alert(data.error);
                    }
                    else{
                        let showLink = document.querySelector('.linkAfter');
                            showLink.innerHTML = `
                            <br/>
                                <h3>Oooopsss! This is a invalid domain, dude <span style="font-weight: initial !important;">😭</span></h3>
                                <h3>Use something like this: https://dankicode.com</h3>
                            <br/>
                            `;
                        
                    }
         });




        
        if(finalLink != ""){
        let showLink = document.querySelector('.linkAfter');
        showLink.innerHTML = `
        <br/>
        Your link is: <a href="`+domain_name+finalLink+`">`+domain_name+finalLink+`</a><br>
        <br/>
        <h3>Do you want unlimited links? Buy now for only $1,00?!</h3><br/>
        
        <a id="btn-purchase" class="btnbuy" href="">Buy Now!</a>
        <br/>
        `;
            

        document.getElementById('btn-purchase').onclick = function(e) {
            handleLogin(e);
        }
    }
    

        
    }else{
        let showLink = document.querySelector('.linkAfter');
        showLink.innerHTML = `
        <br/>
        <h3>You already generated your test link, do you want unlimited links for only $1?</h3><br/>
        
        <a id="btn-purchase" class="btnbuy" href="">Buy Now!</a>
        <br/>
        `;

        document.getElementById('btn-purchase').onclick = function(e) {
            handleLogin(e);
        }
    }
        return false;
    }

    return (
        <div>
        <div className="wrap-items">
        <div className="main-content">
        <header>
            <div className="logo"><img src={logoImage} /></div>
            <div className="menu-lateral">
            <div className="btns-separator-menu">
                <a className="btn-entrar-menu" onClick={(e)=>handleLogin(e)} href=""><BiUserCircle style={{color: '#2a5bd7'}} /> Login</a>
                <a onClick={(e)=>handleLogin(e)} href="" className="btnbuy">BUY NOW</a>
            </div>
            </div>

        </header>

        <div className="bannerBg">
            <h3>Share Links, Short Links and Be Happy <span style={{fontWeight:'initial'}}><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.0181 11.9485L24 12.9823L22.9816 11.9483C21.1251 10.0622 18.5892 9.00008 15.9426 9.00008C13.2961 9.00008 10.7602 10.0622 8.90365 11.9483C5.03212 15.9268 5.03212 22.2646 8.90365 26.2431L19.6889 37.1943C20.826 38.3495 22.3791 39 24 39C25.621 39 27.1741 38.3495 28.3111 37.1943L39.0964 26.2433C42.9679 22.2648 42.9679 15.927 39.0964 11.9485C37.2398 10.0623 34.7039 9.00015 32.0573 9.00014C29.4106 9.00014 26.8747 10.0623 25.0181 11.9485Z" fill="#3773FF"/>
</svg>
</span></h3>
            <br/>
           
        </div>



        <div className="testdrive">
            <form onSubmit={(e)=>shortLinkTest(e)}>
                <input placeholder="Type a domain you want to short!" type="text" id="link-test" />
                <br/>
                <br/>
                <input type="submit" name="acao" value="Generate!" />
            </form>
            <p className="linkAfter"></p>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <footer>
        <p>Danki.ly © 2022 - All rights reserved</p>
        </footer>
        </div>
        <div className="bg-dankily-main"></div>
        </div>
        </div>
    )

}