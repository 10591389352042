import {useState,useEffect} from 'react';
import {auth, db, storage} from './firebase.js';

import {api_url,domain_name} from './Api';
import logoImage from './logo.png';
export default function Produto(props){

    const [premium,setPremium] = useState(false);
    const [loading,setLoading] = useState(true);
    const [links, setLinks] = useState([]);
    const [login,setLogin] = useState([]);
    const [myToken,setMyToken] = useState('');
    

    function deslogar(e){
        e.preventDefault();
        auth.signOut();
        window.location.href="/";
    }

    function redirectPagamento(e){
        e.preventDefault();
        
        const requestOptions = {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
            
        };
        fetch(api_url+'gerarpagamento')
                .then(response => response.json())
                .then(function(data){
                    window.location.href=data.url;
         });
    }

    function encurtarLink(e){
        e.preventDefault();
        let link = document.querySelector('.linkName').value;
        
       
        const requestOptions = {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
            
        };
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(token) {
            fetch(api_url+'insertlink?token='+token+'&link='+link, requestOptions)
                    .then(response => response.json())
                    .then(function(data){
                        if(data.error){
                            alert(data.error);
                        }else{

                        }
        });
        })
        

    }

    function atualizarEstadoConta(){
        if(auth.currentUser != null){
            db.collection('links').doc(auth.currentUser.email).collection('sublinks').orderBy("time",'desc')
            .onSnapshot(function(data){
                    
                        setLinks(data.docs)
                   
         })
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(token) {
    
            fetch(api_url+'checklogin?token='+token)
            .then(response => response.json())
            .then(function(data){
                setMyToken(token);
                setLoading(false);
                setPremium(data.pago);
            }).catch(function(e){
               
            })

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let code = params.get('code');
            if(code != null){
                //Validar para ver se pagou.
              
                    fetch(api_url+'confirmarpagamento?code='+code+'&token='+token)
                    .then(response => response.json())
                    .then(function(data){
                        
                        if(data.sucesso == "verdade"){
                            alert('Pagamento feito com sucesso!');
                            setPremium(true);
    
                        }else{
                            alert("Falha no pagamento :(");
                        }
             });
               
    
                    
            }
        })
    }
    }

    useEffect(() => {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');

        auth.onAuthStateChanged((val)=>{
            if(val){
            
                setLogin({
                nome: val.displayName,
                email:val.email,
                imagem: val.photoURL,
                uid: val.uid
                });
                alert('Hey! Welcome '+val.displayName);
                
            }

            atualizarEstadoConta();

          
                
            })

    
    

    }, [])

    return(
        //
        login?.nome != undefined?(
        <div>
        <div className="produto">
        <header className='loginHeader'>
            <div className="logo"><img className='logo-img' src={logoImage} /></div>
            <div className="menu-lateral">
            <div className="btns-separator-menu">
            <img src={login.imagem} />Hello, <b>{login.nome}</b><span className='separator'></span> <a onClick={(e)=>deslogar(e)} href="#">Exit</a>
            </div>
            </div>

        </header>
            {
            loading == true?
                <div style={{textAlign:'center',marginTop:10}}>Carregando</div>
                
                :
                premium == true?
                <div className="input-encurtador">
                    <form onSubmit={(e)=>encurtarLink(e)}>
                        <input className="linkName" type="text" name="link" placeholder="Create your shorted link..." />
                        <input type="submit" name="acao" value="Short Now!" />
                    </form>
                </div>
                :
                <div className="boxPagtoAll">
               
                <div className="boxPagto">
                    <h2>Premium:</h2>
                    <br/>
                    <p>👉 Unlimited Links Forever.</p>
                    <p>👉 Create Custom Links.</p>
                    <p>👉 Track your links.</p>
                    <p>👉 And much more...</p>
                    <br/>
                    
                    <a className="btnbuy" onClick={(e)=>redirectPagamento(e)} href="#">Pay $1 now</a>
                </div>
                
                </div>
            
            }
        </div>
        <div className="links">
            <h2>Your Premium Links:</h2>
            <div className='table-links'>
            <p>Link</p><p>Redirect to</p>
            </div>
            <div className='table-inside'>
            {
            links?.map(function(val){
                return(
                    <div className='row'>
                    
                    <a style={{color:'#069'}} href={domain_name+val.data().url}>{domain_name}{val.data().url}</a>
                    <p>{val.data().redirect}</p>
                    </div>
                )
            })
            
            }
            </div>
            
            
        </div>
        <br/>
        
        </div>
        )
        :
        <div></div>
        
    )
}