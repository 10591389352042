import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCQ_y9mDF7ymwGMx3OVlE3MhjvNN7tY380",
  authDomain: "danki-ly.firebaseapp.com",
  projectId: "danki-ly",
  storageBucket: "danki-ly.appspot.com",
  messagingSenderId: "971409468818",
  appId: "1:971409468818:web:c7cf6a39f687522046654b",
  measurementId: "G-BNB6THM5NS"
});

 

  const db = firebase.firestore();
  
  const storage  = firebase.storage();

  const auth = firebase.auth();

  const provider = new firebase.auth.GoogleAuthProvider();


  export {auth,provider,db,storage};
  